<template>
	<v-container fluid class="pa-0">
		<v-toolbar flat dense dark :style="stickyTop" extension-height="4">
			<v-toolbar-title>{{ $t("Proef en saldibalans") }} </v-toolbar-title>
			<v-spacer />
			<v-autocomplete style="max-width: 225px" class="text-overline" v-model="journal" :items="journals"
				placeholder="filter op dagboek" hide-details solo flat dense outlined clearable
				append-icon="mdi-filter-outline" @change="refreshBalanceSheet"></v-autocomplete>
			<v-autocomplete style="max-width: 225px" class="ml-4 text-overline" v-model="fiscalPeriod"
				:items="sessionStore.fiscalPeriods" placeholder="filter op periode" hide-details solo flat dense outlined
				clearable append-icon="mdi-filter-outline" @change="refreshBalanceSheet"></v-autocomplete>
			<v-spacer />
			<span>Balans totaal: {{ $n(balanceTotal, "ccy") }}</span>
			<template #extension v-if="loading">
				<v-progress-linear bottom indeterminate color="white"></v-progress-linear>
			</template>
		</v-toolbar>
		<v-container>
			<v-sheet outlined>
				<v-data-table disable-sort disable-pagination hide-default-footer :items="balanceSheet" :headers="headers"
					:height="sessionStore.contentHeight - 76 + 'px'" fixed-header dense>
					<template v-slot:[`item.gnrc`]="{ item }">
						<span :class="item.gnr ? '' : 'font-weight-bold'">{{
							item.gnrc
						}}</span>
					</template>
					<template v-slot:[`item.pdebet`]="{ item }">
						<span
											v-if="item.pdebet"
											:class="item.gnr ? '' : 'font-weight-bold'"
											>{{ $n(item.pdebet, "ccy") }}</span>
					</template>
					<template v-slot:[`item.pcredit`]="{ item }">
						<span
											v-if="item.pcredit"
											:class="item.gnr ? '' : 'font-weight-bold'"
											>{{ $n(item.pcredit, "ccy") }}</span>
					</template>
					<template v-slot:[`item.sdebet`]="{ item }">
						<span
											v-if="item.sdebet"
											:class="item.gnr ? '' : 'font-weight-bold'"
											>{{ $n(item.sdebet, "ccy") }}</span>
					</template>
					<template v-slot:[`item.scredit`]="{ item }">
						<span
											v-if="item.scredit"
											:class="item.gnr ? '' : 'font-weight-bold'"
											>{{ $n(item.scredit, "ccy") }}</span>
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-btn v-if="item.gnr" icon @click.stop="openGeneralLedger(item)">
							<v-icon>mdi-chevron-right</v-icon>
						</v-btn>
					</template>
				</v-data-table>
			</v-sheet>
		</v-container>
	</v-container>
</template>
                
<script>
import caas from "@/services/caas";
import { useSessionStore } from "@/store/session";
export default {
	name: "BalanceSheet",
	computed: {
		balanceTotal() {
			let total = 0;
			this.balanceSheet.forEach((r) => {
				total += r.sdebet;
			});
			return total;
		},
		headers() {
			return [
				{ text: "Rekeningnr", value: "gnrc", width: "60px" },
				{ text: "Omschrijving", value: "omschrijving" },
				{ text: "P Debet", value: "pdebet", align: "end" },
				{ text: "P Credit", value: "pcredit", align: "end" },
				{ text: "S Debet", value: "sdebet", align: "end" },
				{ text: "S Credit", value: "scredit", align: "end" },
				{ text: "", value: "actions" },
			];
		},
		stickyTop() {
			return {
				position: "sticky",
				top: this.$vuetify.application.top + "px",
				zIndex: 5,
			};
		},
	},
	data() {
		return {
			journal: "",
			journals: [
				{ text: "Aankoop dagboek", value: "A" },
				{ text: "Verkoop dagboek", value: "V" },
				{ text: "Financieel dagboek", value: "F" },
				{ text: "Diversen dagboek", value: "D" },
				{ text: "Openings dagboek", value: "O" },
			],
			fiscalPeriod: 0,
			loading: false,
			balanceSheet: [],
			sessionStore: useSessionStore()
		};
	},
	methods: {
		async getBalanceSheet() {
			this.loading = true;
			let params = {
				fiscalYear: Number(this.sessionStore.fiscalYear),
				fromPeriod: this.fiscalPeriod || 0,
				toPeriod: this.fiscalPeriod || 99,
				journal: this.journal || "",
				compact: true,
			};
			const response = await caas.rpc("getBalanceSheet", params);
			if (response.data) {
				this.balanceSheet = response.data.data.tt_balans;
			} else if (response.data.error) {
				console.log(response.error);
			}
			this.loading = false;
		},
		refreshBalanceSheet() {
			this.balanceSheet = [];
			this.getBalanceSheet();
		},
		openGeneralLedger(item) {
			this.$router.push({
				name: "GeneralLedger",
				params: {
					ledgerId: item.gnr,
					ledgerName: item.omschrijving,
					journal: this.journal || "",
					fiscalPeriod: this.fiscalPeriod || 0,
				},
			});
		},
	},
	mounted() {
		this.getBalanceSheet();
	},
};
</script>
